function getTrabajosPreventivos(trabajosPreventivo) {
  if (trabajosPreventivo && Array.isArray(trabajosPreventivo) && trabajosPreventivo.length > 0) {
    return trabajosPreventivo.map(trabajo => trabajo.title).join(', ')
  }
  return ''
}

function getTrabajosCorrectivos(trabajosCorrectivo) {
  if (trabajosCorrectivo && Array.isArray(trabajosCorrectivo) && trabajosCorrectivo.length > 0) {
    return trabajosCorrectivo.map(trabajo => trabajo.title).join(', ')
  }
  return ''
}
export const tituloPrincipal = {
  titulo: 'ALUMBRADO PUBLICO DE VILLA NUEVA, S.A',
  style: {
    font: {
      bold: true,
      size: 20,
      color: '#ffffff',
    },
    fill: {
      type: 'pattern',
      patternType: 'solid',
      fgColor: '#0070c0',
    },
    alignment: {
      horizontal: 'center',
      vertical: 'center',
      wrapText: true,
      width: 15,
    },
  },
}

export const tituloSecundario = {
  titulo: 'CONSERVACIÓN CENTROS DE MANDO P&M',
  style: {
    font: {
      bold: true,
      size: 20,
      color: '#ffffff',
    },
    fill: {
      type: 'pattern',
      patternType: 'solid',
      fgColor: '#0070c0',
    },
    alignment: {
      horizontal: 'center',
      vertical: 'center',
      wrapText: true,
    },
  },
}

export const subtitulo = {
  subtitulo: 'REPORTE GENERAL DE CONSERVACIÓN DE CENTROS DE MANDO P&M',
  style: {
    font: {
      bold: true,
      size: 10,
      color: '#ffffff',
    },
    fill: {
      type: 'pattern',
      patternType: 'solid',
      fgColor: '#0070c0',
    },
    alignment: {
      horizontal: 'center',
      vertical: 'center',
      wrapText: true,
    },
  },
}

export const tituloDescarga = 'REPORTE CONSERVACIÓN DE CENTROS DE MANDO P&M'
export function getColumns() {
  return [
    {
      label: 'Centro de Mando P&M',
      width: 15,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
    {
      label: 'Brigada',
      width: 15,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
    {
      label: 'Localidad',
      width: 30,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
    {
      label: 'Lugar',
      width: 30,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
    {
      label: 'Fecha Creación',
      width: 15,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
    {
      label: 'Coordenadas',
      width: 40,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
    {
      label: 'Mantenimiento Preventivo',
      width: 30,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
    {
      label: 'Mantenimiento Correctivo',
      width: 30,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
    {
      label: 'Observaciones',
      width: 50,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
  ]
}

export function getRows(rows) {
  const listado = []

  rows.forEach(row => {
    const trabajosPreventivo = row.trabajosPreventivo || []
    const trabajosCorrectivo = row.trabajosCorrectivo || []
    const item = {
      noGabinete: {
        value: row.gabinete.poste,
        type: 'string',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        }, // style que va tener la fila en el reporte
      },
      brigada: {
        value: row.brigada.nombre,
        type: 'string',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        },
      },
      localidad: {
        value: row.localidad.nombre,
        type: 'string',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        },
      },
      lugar: {
        value: row.lugar,
        type: 'string',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        },
      },
      fechaCreacion: {
        value: row.fechaCreacion,
        type: 'date',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        },
      },
      coordenadas: {
        value: (row.seguimiento && row.seguimiento.length > 0 && row.seguimiento[0].localization) ? `${row.seguimiento[0].localization.latitude}, ${row.seguimiento[0].localization.longitude}` : '',
        type: 'string',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        },
      },
      preventivo: {
        value: getTrabajosPreventivos(trabajosPreventivo),
        type: 'string',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        },
      },
      correctivo: {
        value: getTrabajosCorrectivos(trabajosCorrectivo),
        type: 'string',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        },
      },
      observaciones: {
        value: row.observaciones,
        type: 'string',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        },
      },
    }
    listado.push(item)
  })

  return listado
}
